import { useState, useEffect } from 'react';
import { FaDivide, FaTimes, FaPlus, FaMinus, FaUndo } from 'react-icons/fa';
import { evaluate } from 'mathjs';
import './App.css';


/* Number Selection Functions */
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function generateNumbersArray(arraySize) {
  const largeNumbers = [25, 50, 75, 100];
  const shuffledLargeNumbers = shuffleArray([...largeNumbers]);
  const numOfLargeNumbers = getRandomInt(1, 4);
  
  const pickedLargeNumbers = shuffledLargeNumbers.slice(0, numOfLargeNumbers);

  const remainingNumbers = arraySize - numOfLargeNumbers;
  const smallNumbers = Array.from({ length: remainingNumbers }, () => getRandomInt(1, 10));
  
  return [...pickedLargeNumbers, ...smallNumbers];
}

/* Solution Calculation */
function evaluateSolution(solution) {
  if (!solution.length) return 0;

  // Convert our solution into a string to go to mathjs
  let expression = solution.join('');

    /*
      Check if the last character of the expression is an operation and trim it if so.
      Otherwise mathjs will throw an error. This comes up when the player has last clicked
      an operation tile.
    */
    const operations = ['+', '-', '*', '/'];
    if (operations.includes(expression[expression.length - 1])) {
        expression = expression.slice(0, -1);
    }

  try {
      return evaluate(expression);
  } catch (e) {
      return "Error";
  }
}

/* Classes */
function Tile({value, className, onClickHandler}) {
  return (
    <button className={className} onClick={onClickHandler}>
      {value}
    </button>
  )
}

function NumberTile({value, onClickHandler}) {
  return <Tile value={value} onClickHandler={onClickHandler} className="App-tile App-tile-number"/>
}

function OperationTile({value, onClickHandler}) {
  return <Tile value={value} onClickHandler={onClickHandler} className="App-tile App-tile-operation"/>
}

/* App */
function App() {
  const [numbers, setNumbers] = useState([]);
  const [target, setTarget] = useState(0);

  useEffect(() => {
    setNumbers(generateNumbersArray(6));
    setTarget(getRandomInt(101,999));
  }, []);

  const numberTiles = numbers.map((number)=> {
    return (
      <NumberTile value={number} onClickHandler={() => OnNumberPressed(number)}/>
    )
  })

  const [solution, setSolution] = useState([]);

  const operationIcons = {
    "/": <FaDivide />,
    "*": <FaTimes />,
    "+": <FaPlus />,
    "-": <FaMinus />
  };

  /* Event Handlers */
  function OnNumberPressed(value) {
    console.log("Number " + value + " pressed!");

    if (solution.length && typeof solution[solution.length - 1] === 'number')
      return;

    // Remove the pressed number from the numbers list
    const updatedNumbers = numbers.filter(number => number !== value);
    setNumbers(updatedNumbers);

    // Add to solution
    setSolution([...solution, value]);
  }

  function OnOperationPressed(value) {
    if (!solution.length || typeof solution[solution.length - 1] === 'string')
      return;

    console.log("Operation " + value + " pressed!");
    setSolution([...solution, value]);
  }

  function OnUndoPressed() {
    console.log("Undo pressed!");
    if (solution.length === 0) return;

    // Add number back to the pool.
    const lastPressedTile = solution[solution.length - 1];
    if (typeof lastPressedTile === 'number') {
      setNumbers([...numbers, lastPressedTile]);
    }

    // Update the solution to pop off the last item.
    setSolution(solution => solution.slice(0, -1));
  }

  return (
    <div className="App">
      <div className="App-title">
        BETHEMATICA
      </div>
      <header className="App-header">
        <div className="App-target-title">Target</div>
        <div className="App-target">
          {target}
        </div>
        <div className="App-vertical-break" />
        <div className="App-number-tray">
          {numberTiles}
        </div>
        <div className="App-operations-tray">
          <OperationTile onClickHandler={() => OnOperationPressed("/")} value=<FaDivide /> />
          <OperationTile onClickHandler={() => OnOperationPressed("*")} value=<FaTimes /> />
          <OperationTile onClickHandler={() => OnOperationPressed("+")} value=<FaPlus /> />
          <OperationTile onClickHandler={() => OnOperationPressed("-")} value=<FaMinus /> />
          <OperationTile onClickHandler={() => OnUndoPressed()} value=<FaUndo /> />
        </div>
        <div className="App-vertical-break" />
        <div>
          {solution.map((tile, index) => (
            typeof tile === 'number' ? 
              <NumberTile key={index} value={tile}/> : 
              <OperationTile key={index} value={operationIcons[tile]} />
          ))}
          <span>= {evaluateSolution(solution)}</span>
        </div>
      </header>
    </div>
  );
}

export default App;
